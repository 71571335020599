import React from 'react'
import Helmet from 'react-helmet'

// import 'semantic-ui-less/semantic.less';

import './styles';

import favicon from '../static/favicon.ico';

const Meta = ({
  seo = {
    url: "https://best-fit.app/",
    title: "BestFit",
    type: "website",
    description: "BestFit uses storytelling, community, and data to makes college decision-making fun, simple & equitable for historically underrepresented students.",
    image: {
      url: "http://static1.squarespace.com/static/5a691066ccc5c597eec07a07/t/5b26fb9b70a6ad7fc922ff0d/1529281437450/INAlogos+color_FavIcon+Color.png?format=1500w",
      width: 174,
      height: 362,
    },
  },
}) => (
  <Helmet>
    <meta charSet="utf-8" />
    <link rel="shortcut icon" type="image/png" href={favicon} />
    {seo.image && <meta name="image" content={seo.image.url} />}
    {seo.url && <meta property="og:url" content={seo.url} />}
    {seo.type && <meta property="og:type" content={seo.type} />}
    {seo.title && <meta property="og:title" content={seo.title} />}
    {seo.description && <meta property="og:description" content={seo.description} />}
    {seo.image && <meta property="og:image" content={seo.image.url} />}
    {seo.image && <meta property="og:image:width" content={seo.image.width} />}
    {seo.image && <meta property="og:image:height" content={seo.image.height} />}
    <meta name="twitter:card" content="summary_large_image" />
    {seo.twitterUsername && <meta name="twitter:creator" content={seo.twitterUsername} />}
    {seo.title && <meta name="twitter:title" content={seo.title} />}
    {seo.description && <meta name="twitter:description" content={seo.description} />}
    {seo.image && <meta name="twitter:image" content={seo.image.url} />}      
  </Helmet>
);

export default Meta;

